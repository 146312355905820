import React from 'react';
import {BREAKPOINT} from '../../../lib/styles';
import {shortcodes} from '../../../lib/shortcodes';
import Layout from '../../Layout'; // AAC2-3811
import ScrollToTop from '../../ScrollToTop';
import PageContent from '../../PageContent';
import InContentCta from '../../InContentCta';
import {getFormattedDate} from 'aac-components/utils/helpers';
import MultiStepVobHero from '../../Hero/MultiStepVobHero';
import {useRouter} from 'next/router';
import {useContext} from 'react';
import AppContext from '../../AppContext';
import Sidebar from './Sidebar';
import ArticleHero from '../../Hero/ArticleHero';
import {MAX_WIDTH_PX} from 'aac-components/utils/styles';
import ContributorCard from './ContributorCard';
import RelatedTopics from '../../RelatedTopics';
import {PageTags} from '../../ContentTags';
import ArticleFeedback from 'aac-components/components/ArticleFeedback';
import FormScrollback from '../../FormScrollback';
import LocalTemplateHero from '../../Hero/LocalTemplateHero';

const DefaultPageTemplate = (props) => {
    const {
        data: {
            id = 0,
            content: {rendered: content = ''} = {},
            title: {rendered: title = ''} = {},
            byline_info = {},
            related_posts = [],
            tags = [],
            modified = '',
        },
    } = props;

    const {asPath} = useRouter();
    const isLocalPage = asPath.includes('/treatment-centers');

    const {isMobile} = useContext(AppContext);

    return (
        <div id="default-template">
            <Layout>
                <InContentCta />
                <InContentCta type="tertiary" />
                <ScrollToTop />
                {isLocalPage ? (
                    <LocalTemplateHero {...props} />
                ) : (
                    <ArticleHero {...props} />
                )}

                <div className="container">
                    <div className="content">
                        <PageContent />

                        <div className="content__footer">
                            <ArticleFeedback />
                            <FormScrollback />
                            <ContributorCard byline_info={byline_info} />
                            <RelatedTopics pages={related_posts} />
                            <PageTags tags={tags} />
                            <div style={{padding: '10px 0'}}>
                                Last Updated on {getFormattedDate(modified)}
                            </div>
                        </div>
                    </div>
                    <Sidebar
                        byline_info={byline_info}
                        related_posts={related_posts}
                        tags={tags}
                    />
                </div>
            </Layout>

            <style jsx>{shortcodes}</style>
            <style jsx>
                {`
                    .container {
                        max-width: ${MAX_WIDTH_PX};
                        margin: 0 auto;
                        padding: 0 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .container {
                            display: grid;
                            grid-template-columns: auto 375px;
                            grid-gap: 55px;
                            padding: 0;
                        }
                    }
                    .content {
                        margin-bottom: 30px;
                        max-width: 100%;
                        overflow: hidden;
                    }
                `}
            </style>
        </div>
    );
};
export default React.memo(DefaultPageTemplate);
